/* eslint-env browser */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Stack,
  ToggleButtonGroup,
  ToggleButton
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import {
  selectCommon,
  getAreaStrings,
  ConfirmationModal,
  ErrorModal
} from '../common'
import {
  shouldShowExperimentalFeatures,
  selectAuth
} from '../auth'
import {
  fetchLayoutResult as PageFetchLayoutResult,
  exportLayout as PageExportLayout
} from '../page/pageAPI'
import {
  modifyLayoutIndex,
  fetchLayoutResult as SubLayoutFetchLayoutResult,
  exportLayout as SubLayoutExportLayout
} from '../subLayout/subLayoutAPI'
import {
  selectPageItem,
  dismissError as PageDismissError
} from '../page/pageSlice'
import {
  dismissError as SubLayoutDismissError,
  selectSubLayoutItem
} from '../subLayout/subLayoutSlice'
import {
  fetchArticles,
  modifyLayoutLocked,
  getLayoutableBaseUrl
} from '../article/articleAPI'
import {
  selectArticle,
  isLayoutEditableStatus
} from '../article'
import {
  closeModal,
  openModal
} from '../article/articleSlice'
import {
  drawPreview,
  clearPreview,
  clearSelectStateFromAllElements,
  clearHoverStateFromAllElements,
  setSelectStateForElements,
  setHoverStateForElements,
  setSvgScale,
  getSvgObject
} from './layout_preview'
import moment from 'moment'

const BASE_HEIGHT = '100vh'
const HEADER_HEIGHT = '100px'

const getPositionStrings = (rect) => {
  const posYString = rect.top !== null ? `${rect.top + 1}段目` : ''
  const posXString = rect.right !== null ? `${rect.right + 1}行目` : ''
  return { posYString, posXString }
}

const normalizeHeadingData = (article, layout) => {
  const headingIds = layout.headingAreas
    .map(headingArea => headingArea.id)
    .filter(id => id !== undefined)
  const normalized = layout.headingAreas.map(headingArea => {
    const lines = headingArea.id !== undefined
      ? article.headings.filter(heading => `heading-${heading.id}` === headingArea.id)
      : article.headings.filter(heading => !headingIds.includes(`heading-${heading.id}`))
    const headingType = headingArea.id !== undefined
      ? lines[0].headingType
      : 'heading'
    const [numberOfOccupyingColumns, numberOfOccupyingRows] = headingType === 'heading'
      ? [article.headingNumberOfOccupyingColumns, article.headingNumberOfOccupyingRows]
      : [lines[0].numberOfOccupyingColumns, lines[0].numberOfOccupyingRows]

    return {
      lines,
      layout: headingArea,
      headingType,
      numberOfOccupyingColumns,
      numberOfOccupyingRows
    }
  })

  return normalized
}

const ObjectTreeArticleItem = (props) => {
  const { enumValues } = useSelector(selectCommon)

  const h5Style = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    fontSize: '1.1rem',
    fontWeight: 'bold'
  }

  const h6Style = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    fontSize: '1.0rem',
    fontWeight: 'bold'
  }

  const itemStyle = (isHovered, isSelected) => {
    return {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
      fontSize: '0.9rem',
      ...(isHovered) && {
        border: 'solid 1px'
      },
      ...(!isHovered) && {
        textDecoration: 'underline'
      },
      ...(isSelected) && {
        backgroundColor: '#fbb'
      }
    }
  }

  const createSelector = (category, index = undefined) => {
    return index !== undefined
      ? `.${props.layout.id}.${category}.index${index}`
      : `.${props.layout.id}.${category}`
  }
  const layoutLockCaution = props.article.layoutLocked ? <><FontAwesomeIcon icon='lock' /> </> : undefined
  const missLayoutCaution = props.layout.areas.length === 0
    ? <><FontAwesomeIcon icon='triangle-exclamation' size='xl' /> </>
    : undefined

  const typeString = enumValues.articleTypes[props.article.articleType]

  const title = props.article.headings.length > 0
    ? props.article.headings[0].content.replace(/(\n)/gm, '')
    : `${typeString} - ${props.article.filename || props.article.name || props.article.id}`

  const headings = props.layout.headingAreas.length > 0
    ? (
      <>
        <h6 style={h6Style}>見出し</h6>
        <ListGroup>
          {
            props.layout.headingAreas.map((headingArea, i) => {
              const headingTypeNames = { ...enumValues.headingTypes, heading: '通常見出し' }
              const selector = createSelector('heading', i)
              const isHovered = selector === props.hoveredElementsSelector
              const isSelected = selector === props.selectedElementsSelector
              const headingType = props.article.headings.find(ah => ah.id === headingArea?.id)?.headingType ?? 'heading'
              const title = headingTypeNames[headingType] ?? '不明な見出し'
              return (
                <ListGroup.Item
                  key={i} style={itemStyle(isHovered, isSelected)}
                  selector={selector}
                  onClick={props.onClickOfItems}
                  onMouseEnter={props.onMouseEnterOfItems}
                  onMouseLeave={props.onMouseLeaveOfItems}
                >
                  {title}
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </>
      )
    : undefined

  const preamble = props.article.preambleContent != null
    ? (
      <>
        <h6 style={h6Style}>前文</h6>
        <ListGroup>
          {
            props.layout.areas.slice(0, 1).map((_, i) => {
              const selector = createSelector('preamble', undefined)
              const isHovered = selector === props.hoveredElementsSelector
              const isSelected = selector === props.selectedElementsSelector
              return (
                <ListGroup.Item
                  key={i} style={itemStyle(isHovered, isSelected)}
                  selector={selector}
                  onClick={props.onClickOfItems}
                  onMouseEnter={props.onMouseEnterOfItems}
                  onMouseLeave={props.onMouseLeaveOfItems}
                >
                  前文エリア
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </>
      )
    : undefined

  const textStart = preamble !== undefined ? 1 : 0
  const textEnd = textStart + 1

  const segments = props.layout.areas.length > 0
    ? (
      <>
        <h6 style={h6Style}>本文</h6>
        <ListGroup>
          {
            props.layout.areas.slice(textStart, textEnd).map((_, i) => {
              const category = props.article.articleType
              const selector = createSelector(category, undefined)
              const isHovered = selector === props.hoveredElementsSelector
              const isSelected = selector === props.selectedElementsSelector
              return (
                <ListGroup.Item
                  key={i} style={itemStyle(isHovered, isSelected)}
                  selector={selector}
                  onClick={props.onClickOfItems}
                  onMouseEnter={props.onMouseEnterOfItems}
                  onMouseLeave={props.onMouseLeaveOfItems}
                >
                  本文エリア
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </>
      )
    : undefined

  const figures = props.article.figures.length > 0
    ? (
      <>
        <h6 style={h6Style}>図表</h6>
        <ListGroup>
          {
            props.article.figures.map((figure, i) => {
              const selector = createSelector('figure', i)
              const isHovered = selector === props.hoveredElementsSelector
              const isSelected = selector === props.selectedElementsSelector
              return (
                <ListGroup.Item
                  key={i} style={itemStyle(isHovered, isSelected)}
                  selector={selector}
                  onClick={props.onClickOfItems}
                  onMouseEnter={props.onMouseEnterOfItems}
                  onMouseLeave={props.onMouseLeaveOfItems}
                >
                  図表エリア {i + 1}
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </>
      )
    : undefined

  return (
    <>
      <h5 style={h5Style}>{layoutLockCaution}{missLayoutCaution}{title}</h5>
      <ListGroup.Item>
        {headings}
        {preamble}
        {segments}
        {figures}
      </ListGroup.Item>
    </>
  )
}

const ObjectTree = (props) => {
  return (
    <>
      <div>
        {
          props.articles.map((article, i) => {
            return (
              <ObjectTreeArticleItem
                article={article.article}
                layout={article.layout}
                key={i}
                selectedElementsSelector={props.selectedElementsSelector}
                hoveredElementsSelector={props.hoveredElementsSelector}
                onClickOfItems={props.onClickOfItems}
                onMouseEnterOfItems={props.onMouseEnterOfItems}
                onMouseLeaveOfItems={props.onMouseLeaveOfItems}
              />
            )
          })
        }
      </div>
    </>
  )
}

const ArticleLayoutLockSwitch = ({
  article,
  openedModal,
  layoutable,
  layoutableType,
  layoutableId,
  currentUser,
  ...props
}) => {
  const dispatch = useDispatch()
  const isLayoutableReadable = layoutable && currentUser &&
        (currentUser.role === 'group_administrator' ||
         currentUser.role === 'supervisor' ||
         currentUser.id === layoutable?.assignee?.id)
  const isLayoutableWritable = isLayoutableReadable && isLayoutEditableStatus(layoutable.status)
  const handleSubmit = ({
    layoutLocked
  }) => {
    const params = {
      layoutableId,
      layoutableType,
      lockVersion: layoutable?.lockVersion,
      article: { id: article.id, layoutLocked }
    }
    console.log('handle Submit', params)
    dispatch(modifyLayoutLocked(params))
  }
  // console.log(props)
  const articleName = article.filename || article.name || article.id || ''
  const [layoutLocked, setLayoutLocked] = useState(article.layoutLocked)
  const modalSetting = {
    name: 'toggle-layout-locked',
    key: 'toggle-layout-locked',
    form: {
      initialValues: {
        layoutLocked
      },
      handleSubmit,
      props: {
        title: 'レイアウトロック変更',
        submitButtonText: '変更する',
        onClickCancel: () => dispatch(closeModal()),
        ConfirmationBody: ({ layoutLocked }) => {
          // console.log('selectedFigure inside ConfirmationBody', fig)
          console.log('body', { layoutLocked, articleLayoutLocked: article.layoutLocked })
          const message = layoutLocked ? 'ロック' : 'ロック解除'
          return (
            <>「{articleName}」のレイアウトを{message}しますか？</>
          )
        },
        layoutLocked,
        article
      }
    }
  }
  return (
    <>
      <ConfirmationModal key={modalSetting.key} {...modalSetting} openedModal={openedModal} />
      <Form
        noValidate
        onSubmit={e =>
          console.log(e)}
      >
        <Form.Check
          type='switch'
          id='articleLayoutLockedSwitch'
          label='レイアウトロック'
          name='layoutLocked'
          defaultValue={article.layoutLocked}
          checked={article.layoutLocked}
          onChange={e => {
            e.preventDefault()
            setLayoutLocked(e.target.checked)
            console.log('before open moday', { layoutLocked, articleLayoutLocked: article.layoutLocked })
            dispatch(openModal('toggle-layout-locked'))
          }}
          disabled={!isLayoutableWritable}
        />
      </Form>
    </>
  )
}

const ObjectProperty = ({
  showExperimentalFeatures,
  ...props
}) => {
  if (props === undefined) {
    return
  }
  const { enumValues } = useSelector(selectCommon)

  const h5Style = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    fontSize: '1.1rem',
    fontWeight: 'bold'
  }

  const h6Style = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    fontSize: '1.0rem',
    fontWeight: 'bold'
  }

  const descriptionStyle = {
    padding: '0.2rem',
    margin: '0.2rem',
    border: 'solid 1px'
  }

  const createArticleTags = (article) => {
    const priority = enumValues.priorities[_.camelCase(article.priority)]
    const articleType = enumValues.articleTypes[_.camelCase(article.articleType)]
    return (
      <>
        <div>
          <h6 style={h6Style}>記事</h6>
          <div>記事ID: {article.id}</div>
          <div>名称: {article.filename || article.name || ''}</div>
          <div>記事種別: {articleType}</div>
          <div>記事優先度: {priority}</div>
          {showExperimentalFeatures &&
            <div><ArticleLayoutLockSwitch article={article} {...props} /></div>}
        </div>
      </>
    )
  }

  const headingTypeNames = { ...enumValues.headingTypes, heading: '通常見出し' }

  const createHeadingTags = (article, layout, index) => {
    const normalizedHeading = normalizeHeadingData(article, layout)[index]
    const title = headingTypeNames[normalizedHeading.headingType] ?? '不明な見出し'

    const { posYString, posXString } = getPositionStrings(normalizedHeading.layout)

    return (
      <>
        <div>
          <h6 style={h6Style}>{title}</h6>
          <div>配置位置: (天から: {posYString}, 右から: {posXString})</div>
          <div>段数: {normalizedHeading.numberOfOccupyingColumns}</div>
          <div>行数: {normalizedHeading.numberOfOccupyingRows}</div>

          <div>内容:</div>
          <ListGroup>
            {normalizedHeading.lines.map((h, i) => {
              const orientation = enumValues.headingOrientations[_.camelCase(h.orientation)]
              const headingType = enumValues.headingTypes[_.camelCase(h.headingType)]
              return (
                <ListGroup.Item as='li' key={i}>
                  <Stack direction='horizontal'>
                    <div>{i}: {headingType}</div>
                    <div className='ms-auto' />
                    <div>{orientation}, {h.content.length}文字</div>
                  </Stack>
                  <div>内容:</div>
                  <div style={descriptionStyle}>{h.content}</div>
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        </div>
      </>
    )
  }

  const createPreambleTags = (article, layout) => {
    if (article.preambleContent == null) {
      return <></>
    }
    const preambleArea = layout.areas[0]
    const { posYString, posXString } = getPositionStrings(preambleArea)
    // console.log('preamble layout', { layout })
    return (
      <>
        <div>
          <h6 style={h6Style}>前文</h6>
          <div>配置位置: (天から: {posYString}, 右から: {posXString})</div>
          <div>文字数: {article.preambleContent.length}</div>
          <div>段数: {article.preambleNumberOfColumns}</div>
          <div>行数: {article.preambleNumberOfRows}</div>
          <div>内容:</div>
          <div style={descriptionStyle}>{article.preambleContent}</div>
        </div>
      </>
    )
  }

  const createFlowTags = (article, layout) => {
    const textStart = article.preambleContent == null ? 0 : 1

    const articleArea = layout.areas[textStart]
    const { posYString, posXString } = getPositionStrings(articleArea)
    return (
      <>
        <div>
          <h6 style={h6Style}>本文</h6>
          <div>配置位置: (天から: {posYString}, 右から: {posXString})</div>
          <div>文字数: {article.bodyContent.length}</div>
          <div>小割数: {layout.areas.length - textStart}</div>
          <div>内容:</div>
          <div style={descriptionStyle}>{article.bodyContent}</div>
        </div>
      </>
    )
  }

  const createAreaTags = (article, layout) => {
    const area = article.areas?.[0]
    if (area === null) {
      return (<></>)
    }
    const { areaYString, areaXString, columnString, rowString } = getAreaStrings(area)
    return (
      <>
        <div>
          <h6 style={h6Style}>{enumValues.articleTypes[article.articleType]}</h6>
          <div>天から: {areaYString}</div>
          <div>右から: {areaXString}</div>
          <div>天地: {columnString}</div>
          <div>左右: {rowString}</div>
        </div>
      </>
    )
  }

  const createTemplateArticleTags = (article, layout) => {
    const area = article.areas?.[0]
    if (area === null) {
      return (<></>)
    }
    const { areaYString, areaXString, columnString, rowString } = getAreaStrings(area)
    return (
      <>
        <div>
          <h6 style={h6Style}>{enumValues.articleTypes[article.articleType]}</h6>
          <div>フレーム数: {article.templateArticles.length}</div>
          <div>天から: {areaYString}</div>
          <div>右から: {areaXString}</div>
          <div>天地: {columnString}</div>
          <div>左右: {rowString}</div>
        </div>
      </>
    )
  }

  const createFigureTags = (article, layout, index) => {
    const figure = article.figures[index]

    if (figure === undefined) {
      return <></>
    }

    const figureType = enumValues.figureTypes[_.camelCase(figure.figureType)]
    const figureArea = layout.figures[index].area
    const { posYString, posXString } = getPositionStrings(figureArea)
    // console.log({ layout, figure, index, figureArea, posString: { posXString, posYString } })
    return (
      <>
        <div>
          <h6 style={h6Style}>図表</h6>
          <div>図表ID: {figure.id}</div>
          <div>ファイル名: {figure?.filename}</div>
          <div>配置位置: (天から: {posYString}, 右から: {posXString})</div>
          <div>種別: {figureType}</div>
        </div>
      </>
    )
  }

  const createTags = (articleId, objectType, index) => {
    const target = props.articleLayoutPairs.find((p) => {
      return p.layout.id === articleId
    })

    if (target === undefined) {
      return <></>
    }

    const article = target.article
    const layout = target.layout

    let propertyTags = (
      <>
        <div>{articleId}</div>
        <div>{objectType}</div>
        <div>{index}</div>
      </>
    )

    switch (objectType) {
      case 'heading':
        propertyTags = createHeadingTags(article, layout, index)
        break
      case 'preamble':
        propertyTags = createPreambleTags(article, layout)
        break
      case 'flow':
        propertyTags = createFlowTags(article, layout)
        break
      case 'area':
        propertyTags = createAreaTags(article, layout)
        break
      case 'template':
        propertyTags = createTemplateArticleTags(article, layout)
        break
      case 'figure':
        propertyTags = createFigureTags(article, layout, index)
        break
    }

    return (
      <>
        <Stack gap='3'>
          {createArticleTags(article)}
          {propertyTags}
        </Stack>
      </>
    )
  }

  let content = (<></>)

  if (props.contentSelector === undefined) {
    content = (
      <>
        <span>未選択</span>
      </>
    )
  } else {
    const [, articleId, objectType, indexString] = props.contentSelector.split('.')
    const index = indexString === undefined
      ? indexString
      : parseInt(indexString.replace('index', ''))
    content = createTags(articleId, objectType, index)
  }

  return (
    <>
      <h5 style={h5Style}>オブジェクトプロパティ</h5>
      {content}
    </>
  )
}

const LayoutIndexSelector = ({
  layoutable,
  layoutIndex,
  setLayoutIndex,
  layoutResultSize
}) => {
  if (layoutResultSize <= 1) {
    return (<></>)
  }
  const dispatch = useDispatch()
  const isWritable = isLayoutEditableStatus(layoutable.status)
  return (
    <>
      <ToggleButtonGroup
        size='sm'
        onChange={layoutIndex => {
          setLayoutIndex(layoutIndex)
          if (isWritable) {
            dispatch(modifyLayoutIndex({ id: layoutable.id, layoutIndex }))
          }
        }}
        type='radio' name='layoutIndex' defaultValue={layoutIndex}
      >
        {_.range(layoutResultSize).map(idx => (
          <ToggleButton
            key={`layoutIndex-option-${idx + 1}`} id={`layoutIndex-${idx + 1}`}
            disabled={!isWritable} variant='outline-primary' value={idx}
          >
            パターン{idx + 1}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  )
}

const LayoutPreview = (props) => {
  const containerStyle = {
    border: 'solid 1px black',
    textAlign: 'center',
    overflowX: 'auto',
    overflowY: 'auto',
    maxHeight: `calc(${BASE_HEIGHT} - ${HEADER_HEIGHT} - 60px)`
  }

  const [svgContainerId] = useState(_.uniqueId('svg-'))
  const [rowIndicator, setRowIndicator] = useState('--')
  const [colIndicator, setColIndicator] = useState('--')
  const [zoomLebel, setZoomLevel] = useState(0)

  const handleExportPNG = (e) => {
    // clear visual state
    clearSelectStateFromAllElements(svgContainerId)
    clearHoverStateFromAllElements(svgContainerId)

    // generate PNG and emit download
    const svg = getSvgObject(svgContainerId)

    if (svg !== undefined) {
      // 非同期処理に関連して本当はクローンした方が良いがsvg.jsのcloneに問題があるため
      // 今のところはクローンせずに処理する。3.1.2より後のバージョンで改修されたら
      // クローンしてからのエクスポートにする。
      // const svg2 = svg.clone()
      const svgBase64 = btoa(unescape(encodeURIComponent(svg.svg())))
      const img = new Image()
      img.src = 'data:image/svg+xml;base64,' + svgBase64
      const canvas = document.createElement('canvas')
      canvas.width = svg.width() * 2
      canvas.height = svg.height() * 2

      img.onload = () => {
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, svg.width(), svg.height(), 0, 0, canvas.width, canvas.height)
        const link = document.createElement('a')
        link.download = 'layout.png'
        link.href = canvas.toDataURL('image/png')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }

    // restore visual state
    if (props.selectedElementsSelector !== undefined) {
      setSelectStateForElements(svgContainerId, props.selectedElementsSelector)
    }
    if (props.hoveredElementsSelector !== undefined) {
      setHoverStateForElements(svgContainerId, props.hoveredElementsSelector)
    }
  }

  const handleExportSVG = (e) => {
    // clear visual state
    clearSelectStateFromAllElements(svgContainerId)
    clearHoverStateFromAllElements(svgContainerId)

    // generate SVG and emit download
    const svg = getSvgObject(svgContainerId)

    if (svg !== undefined) {
      // 非同期処理に関連して本当はクローンした方が良いがsvg.jsのcloneに問題があるため
      // 今のところはクローンせずに処理する。3.1.2より後のバージョンで改修されたら
      // クローンしてからのエクスポートにする。
      // const svg2 = svg.clone()
      const svgBase64 = btoa(unescape(encodeURIComponent(svg.svg())))
      const link = document.createElement('a')
      link.download = 'layout.svg'
      link.href = 'data:image/svg+xml;base64,' + svgBase64
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    // restore visual state
    if (props.selectedElementsSelector !== undefined) {
      setSelectStateForElements(svgContainerId, props.selectedElementsSelector)
    }
    if (props.hoveredElementsSelector !== undefined) {
      setHoverStateForElements(svgContainerId, props.hoveredElementsSelector)
    }
  }

  const { layout, articles, layoutable } = props
  useEffect(
    () => {
      if ([layout, articles, layoutable].some(e => e === null)) {
        return
      }

      const onRowColIndicatorUpdate = (row, col) => {
        if (row < 0 || col < 0) {
          setRowIndicator('--')
          setColIndicator('--')
          return
        }
        setRowIndicator(String(row))
        setColIndicator(String(col))
      }

      drawPreview(
        layout,
        articles,
        layoutable,
        svgContainerId,
        {
          onClickSegment: props.onClickOfSegments,
          onMouseOverSegment: props.onMouseEnterOfSegments,
          onMouseOutSegment: props.onMouseLeaveOfSegments
        },
        onRowColIndicatorUpdate
      )

      return () => {
        clearPreview(svgContainerId)
      }
    },
    [layout, articles]
  )

  useEffect(
    () => {
      clearSelectStateFromAllElements(svgContainerId)

      if (props.selectedElementsSelector !== undefined) {
        setSelectStateForElements(svgContainerId, props.selectedElementsSelector)
      }

      clearHoverStateFromAllElements(svgContainerId)

      if (props.hoveredElementsSelector !== undefined) {
        setHoverStateForElements(svgContainerId, props.hoveredElementsSelector)
      }
    },
    [props.selectedElementsSelector, props.hoveredElementsSelector]
  )

  useEffect(
    () => {
      const factor = [1.0, 1.5, 2.0, 3.0, 5.0][zoomLebel]
      setSvgScale(svgContainerId, factor)
    },
    [zoomLebel]
  )

  const numberOfMissLayout = layout.articles.filter(elem => elem.areas.length === 0).length
  const missLayoutAlert = numberOfMissLayout > 0
    ? (
      <Alert className='position-absolute start-0' variant='warning' style={{ margin: '10px' }}>
        <FontAwesomeIcon icon='triangle-exclamation' size='xl' />
        割り付けされなかった記事が{numberOfMissLayout}本あります。
      </Alert>
      )
    : undefined

  const rowColIndicatorAreaStyle = {
    margin: '10px',
    padding: '5px',
    background: 'white',
    border: '2px dimgray solid',
    borderRadius: '5px'
  }
  const rowColIndicatorStyle = {
    textAlign: 'right', display: 'inline-block', width: '3em'
  }

  const rowColIndicator = (
    <div
      className='position-absolute end-0'
      style={rowColIndicatorAreaStyle}
    >
      <span style={rowColIndicatorStyle}>{colIndicator}段</span>
      <span style={rowColIndicatorStyle}>{rowIndicator}行</span>
    </div>
  )

  return (
    <>
      <Stack direction='vertical' gap={3}>
        <div className='position-relative'>
          {missLayoutAlert}
          {rowColIndicator}
          <div id={svgContainerId} style={containerStyle} />
        </div>
        <Stack direction='horizontal' gap={3}>
          <div>
            <Button
              className='m-1'
              size='sm'
              onClick={handleExportPNG}
            >PNGファイル
            </Button>
            <Button
              className='m-1'
              size='sm'
              onClick={handleExportSVG}
            >SVGファイル
            </Button>
          </div>
          <LayoutIndexSelector {...props} />
          <div className='ms-auto' />
          <div>
            <FontAwesomeIcon icon='magnifying-glass' size='lg' />
          </div>
          <div>
            <ToggleButtonGroup
              size='sm'
              onChange={setZoomLevel}
              type='radio' name='options' defaultValue={0}
            >
              <ToggleButton id='zoomcheck-1' variant='outline-secondary' value={0}> 100% </ToggleButton>
              <ToggleButton id='zoomcheck-2' variant='outline-secondary' value={1}> 150% </ToggleButton>
              <ToggleButton id='zoomcheck-3' variant='outline-secondary' value={2}> 200% </ToggleButton>
              <ToggleButton id='zoomcheck-4' variant='outline-secondary' value={3}> 300% </ToggleButton>
              <ToggleButton id='zoomcheck-5' variant='outline-secondary' value={4}> 500% </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Stack>
      </Stack>
    </>
  )
}

const LayoutViewer = ({
  layoutable,
  layoutResult,
  ...props
}) => {
  if (layoutable === undefined || layoutResult === undefined) {
    return
  }
  const [layoutIndex, setLayoutIndex] = useState(layoutResult?.layoutIndex || 0)

  const selectedLayoutResult = layoutResult?.layoutResults?.[layoutIndex]
  const layoutResultSize = layoutResult?.layoutResults?.length || 0

  const [selectedElementsSelector, setSelectedElementsSelector] = useState(undefined)
  const [hoveredElementsSelector, setHoveredElementsSelector] = useState(undefined)

  const handleChangeOfSelected = (e) => {
    setSelectedElementsSelector(e.target.getAttribute('selector'))
  }

  const handleChangeOfHovered = (e) => {
    setHoveredElementsSelector(e.target.getAttribute('selector'))
  }

  const handleClearOfHovered = (e) => {
    setHoveredElementsSelector(undefined)
  }

  const articles = layoutable.articles

  const articleLayoutPairs = articles?.map((article, i) => {
    return {
      article,
      layout: selectedLayoutResult?.articles.find(elem => `article-${article.id}` === elem.id)
    }
  }).filter(pair => pair.layout !== undefined)

  const layoutPreview = (
    <LayoutPreview
      articles={articles}
      layout={selectedLayoutResult}
      layoutIndex={layoutIndex}
      setLayoutIndex={setLayoutIndex}
      layoutResultSize={layoutResultSize}
      layoutable={layoutable}
      selectedElementsSelector={selectedElementsSelector}
      hoveredElementsSelector={hoveredElementsSelector}
      onClickOfSegments={handleChangeOfSelected}
      onMouseEnterOfSegments={handleChangeOfHovered}
      onMouseLeaveOfSegments={handleClearOfHovered}
      {...props}
    />
  )

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={2} style={{ overflowY: 'auto', maxHeight: `calc(${BASE_HEIGHT} - ${HEADER_HEIGHT})` }}>
            <ObjectTree
              articles={articleLayoutPairs}
              layout={selectedLayoutResult}
              selectedElementsSelector={selectedElementsSelector}
              hoveredElementsSelector={hoveredElementsSelector}
              onClickOfItems={handleChangeOfSelected}
              onMouseEnterOfItems={handleChangeOfHovered}
              onMouseLeaveOfItems={handleClearOfHovered}
            />
          </Col>
          <Col sm={8} style={{ overflowY: 'auto', maxHeight: `calc(${BASE_HEIGHT} - ${HEADER_HEIGHT})` }}>
            {layoutPreview}
          </Col>
          <Col sm={2} style={{ overflowY: 'auto', maxHeight: `calc(${BASE_HEIGHT} - ${HEADER_HEIGHT})` }}>
            <ObjectProperty
              articleLayoutPairs={articleLayoutPairs}
              contentSelector={selectedElementsSelector}
              layoutable={layoutable}
              {...props}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export const LayoutPreviewScreen = ({ layoutableType = 'Page' }) => {
  let layoutableId
  let fetchLayoutResult
  let selector
  let exportLayout
  switch (layoutableType) {
    case 'Page': {
      const { pageId: pageIdString } = useParams()
      layoutableId = parseInt(pageIdString)
      fetchLayoutResult = PageFetchLayoutResult
      selector = selectPageItem
      exportLayout = PageExportLayout
      break
    }
    case 'SubLayout': {
      const { subLayoutId: subLayoutIdString } = useParams()
      layoutableId = parseInt(subLayoutIdString)
      fetchLayoutResult = SubLayoutFetchLayoutResult
      selector = selectSubLayoutItem
      exportLayout = SubLayoutExportLayout
      break
    }
  }
  const { currentUser } = useSelector(selectAuth)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    layoutResult,
    fetching: layoutResultFetching,
    errors
  } = useSelector(selector)

  const {
    layoutable: fetchedLayoutable,
    fetching: articlesFetching,
    data: articles,
    openedModal
  } = useSelector(selectArticle)
  const showExperimentalFeatures = shouldShowExperimentalFeatures()

  let dismissError
  const layoutable = { articles, ...fetchedLayoutable }
  switch (layoutableType) {
    case 'Page':
      dismissError = PageDismissError
      break
    case 'SubLayout':
      dismissError = SubLayoutDismissError
      break
  }
  const [fetching, setFetching] = useState(true)
  // const [reloadRequired, setReloadRequired] = useState(true)
  useEffect(() => {
    if (articlesFetching || layoutResultFetching) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }, [articlesFetching, layoutResultFetching])
  useEffect(() => {
    dispatch(fetchLayoutResult({ id: layoutableId }))
    dispatch(fetchArticles({ layoutableType, layoutableId }))
    // setReloadRequired(false)
  }, [])

  const exportClickHandler = () => {
    const issueDate = moment(layoutable.issueDate).format('YYYYMMDD')
    let fileName
    switch (layoutableType) {
      case 'Page': {
        const page = layoutable
        fileName = `${page.media.name}_${issueDate}_${page.pageNumber}_${page.draftNumber}.zip`
        break
      }
      case 'SubLayout': {
        const subLayout = layoutable
        fileName = `${subLayout.media.name}_${issueDate}_${subLayout.name}_${subLayout.draftNumber}.zip`
        break
      }
    }
    dispatch(exportLayout({ id: layoutableId, fileName }))
  }

  if (fetching || [layoutResult, articles, layoutable].some(e => e === null)) {
    return <></>
  }

  const layoutableBaseUrl = getLayoutableBaseUrl({ layoutableType, layoutableId })
  return (
    <>
      <ErrorModal
        errors={errors}
        onClose={errorKey => dispatch(dismissError(errorKey))}
      />
      <Stack direction='horizontal' gap={3}>
        <div className='ms-auto' />
        <ButtonGroup size='sm' className='m-1'>
          <Button
            className='list-button'
            variant='outline-primary'
            onClick={() => navigate(`/${layoutableBaseUrl}/articles`)}
          >
            <FontAwesomeIcon icon='list' fixedWidth />出稿表
          </Button>
          <Button
            className='export-button'
            variant='outline-primary'
            disabled={!layoutResult || !layoutable || !articles}
            onClick={exportClickHandler}
          >
            <FontAwesomeIcon icon='file-export' fixedWidth />エクスポート
          </Button>
        </ButtonGroup>
      </Stack>
      <LayoutViewer
        articles={articles}
        layoutResult={layoutResult}
        layoutable={layoutable}
        openedModal={openedModal}
        {...{ layoutableType, layoutableId, currentUser, showExperimentalFeatures }}
      />
    </>
  )
}
