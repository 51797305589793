import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Alert,
  Col,
  Button,
  Form,
  InputGroup,
  ListGroup,
  Row
} from 'react-bootstrap'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
import _ from 'lodash'
import { shouldShowExperimentalFeatures } from '../auth'
import {
  selectCell
} from './articleSlice'
import { getColumnString } from '../common'
import {
  createFigure,
  deleteFigure,
  modifyFigure
} from './articleAPI'
import {
  getFigureNumberOfOccupyingRowsFromSetting,
  nullishValueToInternalValue,
  nullishValueToValue,
  stripTags,
  TagEditor
} from '../article'
Yup.setLocale(ja.suggestive)

const FigureFilename = ({
  values,
  errors,
  touched,
  handleBlur,
  // handleChange,
  articleId,
  layoutableType,
  layoutableId,
  figureId,
  holderArticle,
  inputDisabled
}) => {
  if (values?.filename?.length > 0 && holderArticle === undefined) {
    return (
      <Form.Group as={Col}>
        <Form.Label column>ファイル名</Form.Label>
        <Col>
          {values.filename}
        </Col>
      </Form.Group>
    )
  }

  const dispatch = useDispatch()
  if (values?.filename?.length > 0 || holderArticle?.figures?.length > 0) {
    const filenameOptions = values?.filename?.length > 0
      ? [{ figureId, filename: values.filename }]
      : (holderArticle?.figures.map(f => ({ figureId: f.id, filename: f.filename })) || [])
    return (
      <Form.Group as={Col}>
        <Form.Label column>ファイル名</Form.Label>
        <Col>
          <Form.Control
            as='select'
            type='text'
            name='filename'
            size='sm'
            value={values.filename || ''}
            onChange={(e) => {
              const filename = e.target.value
              if (values?.filename?.length > 0) {
                const {
                  filename,
                  location
                } = values
                const unassignFigure = {
                  id: figureId,
                  articleId: holderArticle.id,
                  filename,
                  location
                }
                const {
                  figureType,
                  numberOfOccupyingColumns,
                  numberOfOccupyingRows,
                  fluid,
                  verticalPosition,
                  horizontalPosition,
                  orientation,
                  captionPosition,
                  caption,
                  taggedCaption
                } = values
                const keepFigure = {
                  articleId,
                  figureType,
                  numberOfOccupyingColumns,
                  numberOfOccupyingRows,
                  fluid,
                  verticalPosition,
                  horizontalPosition,
                  orientation,
                  captionPosition,
                  caption,
                  taggedCaption
                }
                dispatch(selectCell(null))
                dispatch(createFigure({ layoutableType, layoutableId, figure: keepFigure }))
                dispatch(modifyFigure({ layoutableType, layoutableId, figure: unassignFigure }))
              } else {
                const newId = filenameOptions.find(f => f.filename === filename).figureId
                const fig = holderArticle.figures.find(f => f.id === newId)
                console.log(`newly assign ${newId}`, fig)
                const {
                  figureType,
                  numberOfOccupyingColumns,
                  numberOfOccupyingRows,
                  fluid,
                  verticalPosition,
                  horizontalPosition,
                  caption,
                  taggedCaption
                } = values
                const newFigure = {
                  ...fig,
                  articleId,
                  figureType,
                  numberOfOccupyingColumns,
                  numberOfOccupyingRows,
                  fluid,
                  verticalPosition,
                  horizontalPosition,
                  caption,
                  taggedCaption
                }
                const delFigure = {
                  id: values.id
                }
                console.log('newFigure', newFigure)
                dispatch(selectCell(null))
                dispatch(deleteFigure({ layoutableType, layoutableId, figure: delFigure }))
                dispatch(modifyFigure({ layoutableType, layoutableId, figure: newFigure }))
              }
            }}
            onBlur={handleBlur}
            isInvalid={touched.filename && errors.filename}
            disabled={inputDisabled}
          >
            <option value=''>画像を選択してください</option>
            {filenameOptions.map(({ figureId, filename }) =>
              (<option key={figureId} value={filename}>{filename}</option>))}
          </Form.Control>
          <Form.Control.Feedback type='invalid'>
            {errors.filename}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    )
  } else {
    return (
      <Form.Group as={Col}>
        <Form.Label column>ファイル名</Form.Label>
        <Col>
          <Alert variant='danger'>割り当て可能な図表類を「複数記事追加」からアップロードしてください。</Alert>
        </Col>
      </Form.Group>
    )
  }
}

export const ArticleFigureForm = ({
  values,
  errors,
  touched,
  isValid,
  handleChange,
  setFieldValue,
  handleBlur,
  handleSubmit,
  enumValues,
  layoutableType,
  layoutableId,
  figureId,
  isSelectedLayoutableWritable,
  holderArticle,
  layoutable,
  article,
  ...props
}) => {
  const dispatch = useDispatch()
  const showExperimentalFeatures = shouldShowExperimentalFeatures()
  const columnOptions = showExperimentalFeatures
    ? _.range(2, 21).map(i => {
      const value = (i * 0.5).toFixed(1)
      const label = getColumnString(value)
      return { label, value }
    })
    : _.range(1, 11).map(i => {
      const value = i.toFixed(1)
      const label = getColumnString(value)
      return { label, value }
    })
  const onFigureCaptionSubmit = (taggedCaption) => {
    const caption = stripTags(taggedCaption)
    const newFigure = {
      ...values,
      caption,
      taggedCaption
    }
    dispatch(modifyFigure({ layoutableType, layoutableId, figure: newFigure }))
    dispatch(selectCell(null))
  }
  const inputDisabled = !isSelectedLayoutableWritable || article.layoutLocked
  return (
    <ListGroup.Item key={figureId}>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(modifyFigure({ layoutableType, layoutableId, figure: values }))
          dispatch(selectCell(null))
        }}
      >
        <Form.Group as={Row}>
          <Form.Label column sm={3}>種別</Form.Label>
          <Col sm={9}>
            <Form.Control
              as='select'
              type='text'
              name='figureType'
              size='sm'
              value={values.figureType || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.figureType && errors.figureType}
              disabled={inputDisabled}
            >
              {Object.keys(enumValues.figureTypes).map(k => (<option key={k} value={k}>{enumValues.figureTypes[k]}</option>))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              {errors.figureType}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={3}>向き</Form.Label>
          <Col sm={9} className='align-items-end'>
            {Object.keys(enumValues.figureOrientations).map(k => (
              <Form.Check
                key={`figure-${k}`}
                inline
                label={enumValues.figureOrientations[k]}
                type='radio'
                name='orientation'
                checked={k === values.orientation}
                value={k}
                onChange={(e) => {
                  const numberOfOccupyingColumns = values.numberOfOccupyingColumns
                  if (numberOfOccupyingColumns !== null) {
                    const orientation = e.target.value
                    const numberOfOccupyingRows = getFigureNumberOfOccupyingRowsFromSetting({
                      figure: { orientation }, numberOfOccupyingColumns, setting: layoutable.setting
                    })
                    setFieldValue('numberOfOccupyingRows', numberOfOccupyingRows)
                  }
                  handleChange(e)
                }}
                disabled={inputDisabled}
              />
            ))}
          </Col>
        </Form.Group>
        <Row>
          <Form.Label column sm={3}>使用寸法</Form.Label>
          <Col sm={9}>
            <Row>
              <Form.Group as={Col} sm={6}>
                <InputGroup size='sm' hasValidation>
                  <Form.Control
                    id='columnsInput'
                    type='number'
                    as='select'
                    name='numberOfOccupyingColumns'
                    size='sm'
                    value={values.numberOfOccupyingColumns || ''}
                    onChange={(e) => {
                      const numberOfOccupyingColumns = e.target.value
                      const numberOfOccupyingRows = getFigureNumberOfOccupyingRowsFromSetting({ figure: values, numberOfOccupyingColumns, setting: layoutable.setting })
                      setFieldValue('numberOfOccupyingRows', numberOfOccupyingRows)
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.numberOfOccupyingColumns && errors.numberOfOccupyingColumns}
                    disabled={inputDisabled}
                  >
                    <option value=''>--段</option>
                    {columnOptions.map(({ label, value }, idx) => (<option key={idx} value={value}>{label}</option>))}
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    {errors.numberOfOccupyingColumns}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} sm={6}>
                <InputGroup size='sm' hasValidation>
                  <Form.Control
                    id='rowsInput'
                    type='number'
                    name='numberOfOccupyingRows'
                    size='sm'
                    value={values.numberOfOccupyingRows || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.numberOfOccupyingRows && errors.numberOfOccupyingRows}
                    disabled={inputDisabled}
                  />
                  <InputGroup.Text>行</InputGroup.Text>
                  <Form.Control.Feedback type='invalid'>
                    {errors.numberOfOccupyingRows}
                  </Form.Control.Feedback>
                </InputGroup>
                {/*
                <Form.Check
                  type='switch'
                  id='fluidSwitch'
                  label='ナリ'
                  name='fluid'
                  size='sm'
                  value={values.fluid}
                  checked={values.fluid}
                  onChange={(e) => {
                    console.log('fluid', e.target.checked)
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  isInvalid={touched.fluid && errors.fluid}
                  disabled={!isSelectedLayoutableWritable}
                />
                */}
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row>
          <Form.Label column sm={3}>位置</Form.Label>
          <Col sm={9}>
            <Row>
              <Form.Group as={Col} sm={6}>
                <InputGroup size='sm' hasValidation>
                  <Form.Control
                    id='vPosInput'
                    type='number'
                    name='verticalPosition'
                    size='sm'
                    value={nullishValueToValue(values.verticalPosition)}
                    placeholder='ナリ'
                    onChange={(e) => {
                      const verticalPosition = nullishValueToInternalValue(e.target.value)
                      setFieldValue('verticalPosition', verticalPosition)
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.verticalPosition && errors.verticalPosition}
                    disabled={inputDisabled}
                  />
                  <InputGroup.Text>段目</InputGroup.Text>
                  <Form.Control.Feedback type='invalid'>
                    {errors.verticalPosition}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} sm={6}>
                <InputGroup size='sm' hasValidation>
                  <Form.Control
                    id='hPosInput'
                    type='number'
                    name='horizontalPosition'
                    size='sm'
                    value={nullishValueToValue(values.horizontalPosition)}
                    placeholder='ナリ'
                    onChange={(e) => {
                      const horizontalPosition = nullishValueToInternalValue(e.target.value)
                      setFieldValue('horizontalPosition', horizontalPosition)
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.horizontalPosition && errors.horizontalPosition}
                    disabled={inputDisabled}
                  />
                  <InputGroup.Text>行目</InputGroup.Text>
                  <Form.Control.Feedback type='invalid'>
                    {errors.horizontalPosition}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row>
          <FigureFilename
            {...props}
            {...{ values, errors, touched, handleBlur, handleChange, holderArticle, isSelectedLayoutableWritable, figureId, layoutableType, layoutableId, inputDisabled }}
          />
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label column>{values.figureType === 'drawing' ? '柱' : 'エトキ'}</Form.Label>
            <Col>
              <TagEditor
                initialTaggedContent={values.taggedCaption}
                styleTagType='caption'
                setting={layoutable.setting}
                onSubmit={onFigureCaptionSubmit}
                disabled={inputDisabled}
                editable={!inputDisabled}
                allowLineBreak
                allowMultiParagraph={false}
              />
            </Col>
          </Form.Group>
        </Row>
        <Form.Group as={Row}>
          <Form.Label column sm={3}>配置位置</Form.Label>
          <Col sm={9} className='align-items-end'>
            {Object.keys(enumValues.captionPositions).map(k => (
              <Form.Check
                inline
                key={`figure-caption-position-${k}`}
                label={enumValues.captionPositions[k]}
                type='radio'
                name='captionPosition'
                size='sm'
                checked={k === values.captionPosition}
                value={k}
                onChange={handleChange}
                disabled={inputDisabled}
              />
            ))}
          </Col>
        </Form.Group>
        <br />
        <Row>
          <Col>
            <Button type='submit' disabled={inputDisabled || !isValid} size='sm'>更新</Button>
          </Col>
          <Col className='d-flex justify-content-end'>
            <Button
              variant='danger'
              disabled={inputDisabled || (values?.filename?.length > 0)}
              size='sm'
              onClick={(e) => {
                e.preventDefault()
                const delFigure = {
                  id: values.id
                }
                dispatch(deleteFigure({ layoutableType, layoutableId, figure: delFigure }))
                dispatch(selectCell(null))
              }}
            >
              削除
            </Button>
          </Col>
        </Row>
      </Form>
    </ListGroup.Item>
  )
}

const positionErrorMessage = '1以上にしてください'

ArticleFigureForm.schema = Yup.object().shape({
  figureType: Yup.string().required(),
  filename: Yup.string().nullable(true),
  numberOfOccupyingColumns: Yup.number().min(1).nullable(true),
  numberOfOccupyingRows: Yup.number().min(1).nullable(true),
  fluid: Yup.boolean().required(),
  verticalPosition: Yup.number().min(0, positionErrorMessage).nullable(true),
  horizontalPosition: Yup.number().min(0, positionErrorMessage).nullable(true),
  orientation: Yup.string().required(),
  captionPosition: Yup.string().required()
})
